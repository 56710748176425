body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Poppins-Semi-Bold';
  src: url('./assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('./assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Thin';
  src: url('./assets/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraLight';
  src: url('./assets/fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
}
   
@font-face {
  font-family: 'Avenir-LT-35-Light';
  src: url('./assets/fonts/Avenir-LT-35-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Reklame-Medium';
  src: url('./assets/fonts/Reklame Script W00 Medium.ttf') format('truetype');
}

@font-face {
  font-family: "Oswald-Medium";
  src: url("./assets/fonts/Oswald/Oswald-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Nimbus-Sans";
  src: url("./assets/fonts/nimbus-sans-l/NimbusSanL-Reg.otf") format("truetype");
}

@font-face {
  font-family: "Agenor";
  src: url("./assets/fonts/Agenor/Agenor-Thin.ttf") format("truetype");
}


@font-face {
  font-family: "Font1";
  src: url("./assets/fonts/Font1.ttf") format("truetype");
}

@font-face {
  font-family: "Font2";
  src: url("./assets/fonts/Font2.ttf") format("truetype");
}

@font-face {
  font-family: "Font3";
  src: url("./assets/fonts/Font3.ttf") format("truetype");
}

@font-face {
  font-family: "Font4";
  src: url("./assets/fonts/Font4.ttf") format("truetype");
}

@font-face {
  font-family: "Font5";
  src: url("./assets/fonts/Font5.ttf") format("truetype");
}

@font-face {
  font-family: "Font6";
  src: url("./assets/fonts/Font6.ttf") format("truetype");
}

@font-face {
  font-family: "Font7";
  src: url("./assets/fonts/Font7.ttf") format("truetype");
}

@font-face {
  font-family: "Font8";
  src: url("./assets/fonts/Font8.ttf") format("truetype");
}

@font-face {
  font-family: "Font9";
  src: url("./assets/fonts/Font9.ttf") format("truetype");
}

@font-face {
  font-family: "Font10";
  src: url("./assets/fonts/Font10.ttf") format("truetype");
}

@font-face {
  font-family: "Font11";
  src: url("./assets/fonts/Font11.ttf") format("truetype");
}

@font-face {
  font-family: "Font12";
  src: url("./assets/fonts/Font12.ttf") format("truetype");
}

@font-face {
  font-family: "Font13";
  src: url("./assets/fonts/Font13.ttf") format("truetype");
}

@font-face {
  font-family: "Font14";
  src: url("./assets/fonts/Font14.ttf") format("truetype");
}

@font-face {
  font-family: "Font15";
  src: url("./assets/fonts/Font15.ttf") format("truetype");
}

@font-face {
  font-family: "Font16";
  src: url("./assets/fonts/Font16.ttf") format("truetype");
}

@font-face {
  font-family: "Font17";
  src: url("./assets/fonts/Font17.ttf") format("truetype");
}

@font-face {
  font-family: "Font18";
  src: url("./assets/fonts/Font18.ttf") format("truetype");
}

@font-face {
  font-family: "Font19";
  src: url("./assets/fonts/Font19.ttf") format("truetype");
}

@font-face {
  font-family: "Font20";
  src: url("./assets/fonts/Font20.ttf") format("truetype");
}

@font-face {
  font-family: "Font21";
  src: url("./assets/fonts/Font21.ttf") format("truetype");
}

@font-face {
  font-family: "Font22";
  src: url("./assets/fonts/Font22.ttf") format("truetype");
}

@font-face {
  font-family: "Font23";
  src: url("./assets/fonts/Font23.ttf") format("truetype");
}

@font-face {
  font-family: "Font24";
  src: url("./assets/fonts/Font24.ttf") format("truetype");
}

@font-face {
  font-family: "Font25";
  src: url("./assets/fonts/Font25.ttf") format("truetype");
}

@font-face {
  font-family: "Font26";
  src: url("./assets/fonts/Font26.ttf") format("truetype");
}

@font-face {
  font-family: "Font27";
  src: url("./assets/fonts/Font27.ttf") format("truetype");
}

@font-face {
  font-family: "Font28";
  src: url("./assets/fonts/Font28.ttf") format("truetype");
}

@font-face {
  font-family: "Font29";
  src: url("./assets/fonts/Font29.ttf") format("truetype");
}

@font-face {
  font-family: "Font30";
  src: url("./assets/fonts/Font30.ttf") format("truetype");
}

@font-face {
  font-family: "Font31";
  src: url("./assets/fonts/Font31.ttf") format("truetype");
}

@font-face {
  font-family: "Font32";
  src: url("./assets/fonts/Font32.ttf") format("truetype");
}

@font-face {
  font-family: "Font33";
  src: url("./assets/fonts/Font33.ttf") format("truetype");
}

@font-face {
  font-family: "Font34";
  src: url("./assets/fonts/Font34.ttf") format("truetype");
}

@font-face {
  font-family: "Font35";
  src: url("./assets/fonts/Font35.ttf") format("truetype");
}

@font-face {
  font-family: "Font36";
  src: url("./assets/fonts/Font36.ttf") format("truetype");
}

@font-face {
  font-family: "Font37";
  src: url("./assets/fonts/Font37.ttf") format("truetype");
}

@font-face {
  font-family: "Font38";
  src: url("./assets/fonts/Font38.ttf") format("truetype");
}

@font-face {
  font-family: "Font39";
  src: url("./assets/fonts/Font39.ttf") format("truetype");
}

@font-face {
  font-family: "Font40";
  src: url("./assets/fonts/Font40.ttf") format("truetype");
}

@font-face {
  font-family: "Font41";
  src: url("./assets/fonts/Font41.ttf") format("truetype");
}

@font-face {
  font-family: "Font42";
  src: url("./assets/fonts/Font42.ttf") format("truetype");
}

@font-face {
  font-family: "Font43";
  src: url("./assets/fonts/Font43.ttf") format("truetype");
}

@font-face {
  font-family: "Font44";
  src: url("./assets/fonts/Font44.ttf") format("truetype");
}

@font-face {
  font-family: "Font45";
  src: url("./assets/fonts/Font45.ttf") format("truetype");
}

@font-face {
  font-family: "Font46";
  src: url("./assets/fonts/Font46.ttf") format("truetype");
}

@font-face {
  font-family: "Font47";
  src: url("./assets/fonts/Font47.ttf") format("truetype");
}

@font-face {
  font-family: "Font48";
  src: url("./assets/fonts/Font48.ttf") format("truetype");
}

@font-face {
  font-family: "Font49";
  src: url("./assets/fonts/Font49.ttf") format("truetype");
}

@font-face {
  font-family: "Font50";
  src: url("./assets/fonts/Font50.ttf") format("truetype");
}

@font-face {
  font-family: "Font51";
  src: url("./assets/fonts/Font51.ttf") format("truetype");
}

@font-face {
  font-family: "Font52";
  src: url("./assets/fonts/Font52.ttf") format("truetype");
}

@font-face {
  font-family: "Font53";
  src: url("./assets/fonts/Font53.ttf") format("truetype");
}

@font-face {
  font-family: "Font54";
  src: url("./assets/fonts/Font54.ttf") format("truetype");
}

@font-face {
  font-family: "Font55";
  src: url("./assets/fonts/Font55.ttf") format("truetype");
}

@font-face {
  font-family: "Font56";
  src: url("./assets/fonts/Font56.ttf") format("truetype");
}

@font-face {
  font-family: "Font57";
  src: url("./assets/fonts/Font57.ttf") format("truetype");
}

@font-face {
  font-family: "Font58";
  src: url("./assets/fonts/Font58.ttf") format("truetype");
}

@font-face {
  font-family: "Font59";
  src: url("./assets/fonts/Font59.ttf") format("truetype");
}

@font-face {
  font-family: "Font60";
  src: url("./assets/fonts/Font60.ttf") format("truetype");
}

@font-face {
  font-family: "Font61";
  src: url("./assets/fonts/Font61.ttf") format("truetype");
}

@font-face {
  font-family: "Font62";
  src: url("./assets/fonts/Font62.ttf") format("truetype");
}

@font-face {
  font-family: "Font63";
  src: url("./assets/fonts/Font63.ttf") format("truetype");
}

@font-face {
  font-family: "Font64";
  src: url("./assets/fonts/Font64.ttf") format("truetype");
}

@font-face {
  font-family: "Font65";
  src: url("./assets/fonts/Font65.ttf") format("truetype");
}

@font-face {
  font-family: "Font66";
  src: url("./assets/fonts/Font66.ttf") format("truetype");
}

@font-face {
  font-family: "Font67";
  src: url("./assets/fonts/Font67.ttf") format("truetype");
}

@font-face {
  font-family: "Font68";
  src: url("./assets/fonts/Font68.ttf") format("truetype");
}

@font-face {
  font-family: "Font69";
  src: url("./assets/fonts/Font69.ttf") format("truetype");
}

@font-face {
  font-family: "Font70";
  src: url("./assets/fonts/Font70.ttf") format("truetype");
}

@font-face {
  font-family: "Font71";
  src: url("./assets/fonts/Font71.ttf") format("truetype");
}

@font-face {
  font-family: "Font72";
  src: url("./assets/fonts/Font72.ttf") format("truetype");
}

@font-face {
  font-family: "Font73";
  src: url("./assets/fonts/Font73.ttf") format("truetype");
}

@font-face {
  font-family: "Font74";
  src: url("./assets/fonts/Font74.ttf") format("truetype");
}

@font-face {
  font-family: "Font75";
  src: url("./assets/fonts/Font75.ttf") format("truetype");
}

@font-face {
  font-family: "Font76";
  src: url("./assets/fonts/Font76.ttf") format("truetype");
}

@font-face {
  font-family: "Font77";
  src: url("./assets/fonts/Font77.ttf") format("truetype");
}

@font-face {
  font-family: "Font78";
  src: url("./assets/fonts/Font78.ttf") format("truetype");
}

@font-face {
  font-family: "Font79";
  src: url("./assets/fonts/Font79.ttf") format("truetype");
}

@font-face {
  font-family: "Font80";
  src: url("./assets/fonts/Font80.ttf") format("truetype");
}

@font-face {
  font-family: "Font81";
  src: url("./assets/fonts/Font81.ttf") format("truetype");
}

@font-face {
  font-family: "Font82";
  src: url("./assets/fonts/Font82.ttf") format("truetype");
}

@font-face {
  font-family: "Font83";
  src: url("./assets/fonts/Font83.ttf") format("truetype");
}

@font-face {
  font-family: "Font84";
  src: url("./assets/fonts/Font84.ttf") format("truetype");
}

@font-face {
  font-family: "Font85";
  src: url("./assets/fonts/Font85.ttf") format("truetype");
}

@font-face {
  font-family: "Font86";
  src: url("./assets/fonts/Font86.ttf") format("truetype");
}

@font-face {
  font-family: "Font87";
  src: url("./assets/fonts/Font87.ttf") format("truetype");
}

@font-face {
  font-family: "Font88";
  src: url("./assets/fonts/Font88.ttf") format("truetype");
}

@font-face {
  font-family: "Font89";
  src: url("./assets/fonts/Font89.ttf") format("truetype");
}

@font-face {
  font-family: "Font90";
  src: url("./assets/fonts/Font90.ttf") format("truetype");
}

@font-face {
  font-family: "Font91";
  src: url("./assets/fonts/Font91.ttf") format("truetype");
}

@font-face {
  font-family: "Font92";
  src: url("./assets/fonts/Font92.ttf") format("truetype");
}

@font-face {
  font-family: "Font93";
  src: url("./assets/fonts/Font93.ttf") format("truetype");
}

@font-face {
  font-family: "Font94";
  src: url("./assets/fonts/Font94.ttf") format("truetype");
}

@font-face {
  font-family: "Font95";
  src: url("./assets/fonts/Font95.ttf") format("truetype");
}

@font-face {
  font-family: "Font96";
  src: url("./assets/fonts/Font96.ttf") format("truetype");
}

@font-face {
  font-family: "Font97";
  src: url("./assets/fonts/Font97.ttf") format("truetype");
}

@font-face {
  font-family: "Font98";
  src: url("./assets/fonts/Font98.ttf") format("truetype");
}

@font-face {
  font-family: "Font99";
  src: url("./assets/fonts/Font99.ttf") format("truetype");
}

@font-face {
  font-family: "Font100";
  src: url("./assets/fonts/Font100.ttf") format("truetype");
}

@font-face {
  font-family: "Font101";
  src: url("./assets/fonts/Font101.ttf") format("truetype");
}

@font-face {
  font-family: "Font102";
  src: url("./assets/fonts/Font102.ttf") format("truetype");
}

@font-face {
  font-family: "Font103";
  src: url("./assets/fonts/Font103.ttf") format("truetype");
}

@font-face {
  font-family: "Font104";
  src: url("./assets/fonts/Font104.ttf") format("truetype");
}

@font-face {
  font-family: "Font105";
  src: url("./assets/fonts/Font105.ttf") format("truetype");
}

@font-face {
  font-family: "Font106";
  src: url("./assets/fonts/Font106.ttf") format("truetype");
}

@font-face {
  font-family: "Font107";
  src: url("./assets/fonts/Font107.ttf") format("truetype");
}

@font-face {
  font-family: "Font108";
  src: url("./assets/fonts/Font108.ttf") format("truetype");
}

@font-face {
  font-family: "Font109";
  src: url("./assets/fonts/Font109.ttf") format("truetype");
}

@font-face {
  font-family: "Font110";
  src: url("./assets/fonts/Font110.ttf") format("truetype");
}

@font-face {
  font-family: "Font111";
  src: url("./assets/fonts/Font111.ttf") format("truetype");
}

@font-face {
  font-family: "Font112";
  src: url("./assets/fonts/Font112.ttf") format("truetype");
}

@font-face {
  font-family: "Font113";
  src: url("./assets/fonts/Font113.ttf") format("truetype");
}

@font-face {
  font-family: "Font114";
  src: url("./assets/fonts/Font114.ttf") format("truetype");
}

@font-face {
  font-family: "Font115";
  src: url("./assets/fonts/Font115.ttf") format("truetype");
}

@font-face {
  font-family: "Font116";
  src: url("./assets/fonts/Font116.ttf") format("truetype");
}

@font-face {
  font-family: "Font117";
  src: url("./assets/fonts/Font117.ttf") format("truetype");
}

@font-face {
  font-family: "Font118";
  src: url("./assets/fonts/Font118.ttf") format("truetype");
}

@font-face {
  font-family: "Font119";
  src: url("./assets/fonts/Font119.ttf") format("truetype");
}

@font-face {
  font-family: "Font120";
  src: url("./assets/fonts/Font120.ttf") format("truetype");
}

@font-face {
  font-family: "Font121";
  src: url("./assets/fonts/Font121.ttf") format("truetype");
}

@font-face {
  font-family: "Font122";
  src: url("./assets/fonts/Font122.ttf") format("truetype");
}

@font-face {
  font-family: "Font123";
  src: url("./assets/fonts/Font123.ttf") format("truetype");
}

@font-face {
  font-family: "Font124";
  src: url("./assets/fonts/Font124.ttf") format("truetype");
}

@font-face {
  font-family: "Font125";
  src: url("./assets/fonts/Font125.ttf") format("truetype");
}

@font-face {
  font-family: "Font126";
  src: url("./assets/fonts/Font126.ttf") format("truetype");
}

@font-face {
  font-family: "Font127";
  src: url("./assets/fonts/Font127.ttf") format("truetype");
}

@font-face {
  font-family: "Font128";
  src: url("./assets/fonts/Font128.ttf") format("truetype");
}

@font-face {
  font-family: "Font129";
  src: url("./assets/fonts/Font129.ttf") format("truetype");
}

@font-face {
  font-family: "Font130";
  src: url("./assets/fonts/Font130.ttf") format("truetype");
}

@font-face {
  font-family: "Font131";
  src: url("./assets/fonts/Font131.ttf") format("truetype");
}

@font-face {
  font-family: "Font132";
  src: url("./assets/fonts/Font132.ttf") format("truetype");
}

@font-face {
  font-family: "Font133";
  src: url("./assets/fonts/Font133.ttf") format("truetype");
}

@font-face {
  font-family: "Font134";
  src: url("./assets/fonts/Font134.ttf") format("truetype");
}

@font-face {
  font-family: "Font135";
  src: url("./assets/fonts/Font135.ttf") format("truetype");
}

@font-face {
  font-family: "Font136";
  src: url("./assets/fonts/Font136.ttf") format("truetype");
}

@font-face {
  font-family: "Font137";
  src: url("./assets/fonts/Font137.ttf") format("truetype");
}

@font-face {
  font-family: "Font138";
  src: url("./assets/fonts/Font138.ttf") format("truetype");
}

@font-face {
  font-family: "Font139";
  src: url("./assets/fonts/Font139.ttf") format("truetype");
}

@font-face {
  font-family: "Font140";
  src: url("./assets/fonts/Font140.ttf") format("truetype");
}

@font-face {
  font-family: "Font141";
  src: url("./assets/fonts/Font141.ttf") format("truetype");
}

@font-face {
  font-family: "Font142";
  src: url("./assets/fonts/Font142.ttf") format("truetype");
}

@font-face {
  font-family: "Font143";
  src: url("./assets/fonts/Font143.ttf") format("truetype");
}

@font-face {
  font-family: "Font144";
  src: url("./assets/fonts/Font144.ttf") format("truetype");
}

@font-face {
  font-family: "Font145";
  src: url("./assets/fonts/Font145.ttf") format("truetype");
}

@font-face {
  font-family: "Font146";
  src: url("./assets/fonts/Font146.ttf") format("truetype");
}

@font-face {
  font-family: "Font147";
  src: url("./assets/fonts/Font147.ttf") format("truetype");
}

@font-face {
  font-family: "Font148";
  src: url("./assets/fonts/Font148.ttf") format("truetype");
}

@font-face {
  font-family: "Font149";
  src: url("./assets/fonts/Font149.ttf") format("truetype");
}

@font-face {
  font-family: "Font150";
  src: url("./assets/fonts/Font150.ttf") format("truetype");
}

@font-face {
  font-family: "Font151";
  src: url("./assets/fonts/Font151.ttf") format("truetype");
}

@font-face {
  font-family: "Font152";
  src: url("./assets/fonts/Font152.ttf") format("truetype");
}


@font-face {
  font-family: "Font153";
  src: url("./assets/fonts/Font153.ttf") format("truetype");
}

@font-face {
  font-family: "Font154";
  src: url("./assets/fonts/Font154.ttf") format("truetype");
}

@font-face {
  font-family: "Font155";
  src: url("./assets/fonts/Font155.ttf") format("truetype");
}

@font-face {
  font-family: "Font156";
  src: url("./assets/fonts/Font156.ttf") format("truetype");
}

@font-face {
  font-family: "Font157";
  src: url("./assets/fonts/Font157.ttf") format("truetype");
}

@font-face {
  font-family: "Font158";
  src: url("./assets/fonts/Font158.ttf") format("truetype");
}

@font-face {
  font-family: "Font159";
  src: url("./assets/fonts/Font159.ttf") format("truetype");
}

@font-face {
  font-family: "Font160";
  src: url("./assets/fonts/Font160.ttf") format("truetype");
}

@font-face {
  font-family: "Font161";
  src: url("./assets/fonts/Font161.ttf") format("truetype");
}

@font-face {
  font-family: "Font162";
  src: url("./assets/fonts/Font162.ttf") format("truetype");
}

@font-face {
  font-family: "Font163";
  src: url("./assets/fonts/Font163.ttf") format("truetype");
}

@font-face {
  font-family: "Font164";
  src: url("./assets/fonts/Font164.ttf") format("truetype");
}

@font-face {
  font-family: "Font165";
  src: url("./assets/fonts/Font165.ttf") format("truetype");
}

@font-face {
  font-family: "Font166";
  src: url("./assets/fonts/Font166.ttf") format("truetype");
}

@font-face {
  font-family: "Font167";
  src: url("./assets/fonts/Font167.ttf") format("truetype");
}

@font-face {
  font-family: "Font168";
  src: url("./assets/fonts/Font168.ttf") format("truetype");
}

@font-face {
  font-family: "Font169";
  src: url("./assets/fonts/Font169.ttf") format("truetype");
}

@font-face {
  font-family: "Font170";
  src: url("./assets/fonts/Font170.ttf") format("truetype");
}

@font-face {
  font-family: "Font171";
  src: url("./assets/fonts/Font171.ttf") format("truetype");
}

@font-face {
  font-family: "Font172";
  src: url("./assets/fonts/Font172.ttf") format("truetype");
}

@font-face {
  font-family: "Font173";
  src: url("./assets/fonts/Font173.ttf") format("truetype");
}

@font-face {
  font-family: "Font174";
  src: url("./assets/fonts/Font174.ttf") format("truetype");
}

@font-face {
  font-family: "Font175";
  src: url("./assets/fonts/Font175.ttf") format("truetype");
}

@font-face {
  font-family: "Font176";
  src: url("./assets/fonts/Font176.ttf") format("truetype");
}

@font-face {
  font-family: "Font177";
  src: url("./assets/fonts/Font177.ttf") format("truetype");
}

@font-face {
  font-family: "Font178";
  src: url("./assets/fonts/Font178.ttf") format("truetype");
}

@font-face {
  font-family: "Font179";
  src: url("./assets/fonts/Font179.ttf") format("truetype");
}

@font-face {
  font-family: "Font180";
  src: url("./assets/fonts/Font180.ttf") format("truetype");
}

@font-face {
  font-family: "Font181";
  src: url("./assets/fonts/Font181.ttf") format("truetype");
}

@font-face {
  font-family: "Font182";
  src: url("./assets/fonts/Font182.ttf") format("truetype");
}

@font-face {
  font-family: "Font183";
  src: url("./assets/fonts/Font183.ttf") format("truetype");
}

@font-face {
  font-family: "Font184";
  src: url("./assets/fonts/Font184.ttf") format("truetype");
}

@font-face {
  font-family: "Font185";
  src: url("./assets/fonts/Font185.ttf") format("truetype");
}

@font-face {
  font-family: "Font186";
  src: url("./assets/fonts/Font186.ttf") format("truetype");
}

@font-face {
  font-family: "Font187";
  src: url("./assets/fonts/Font187.ttf") format("truetype");
}

@font-face {
  font-family: "Font188";
  src: url("./assets/fonts/Font188.ttf") format("truetype");
}

@font-face {
  font-family: "Font189";
  src: url("./assets/fonts/Font189.ttf") format("truetype");
}

@font-face {
  font-family: "Font190";
  src: url("./assets/fonts/Font190.ttf") format("truetype");
}

@font-face {
  font-family: "Font191";
  src: url("./assets/fonts/Font191.ttf") format("truetype");
}

@font-face {
  font-family: "Font192";
  src: url("./assets/fonts/Font192.ttf") format("truetype");
}

@font-face {
  font-family: "Font193";
  src: url("./assets/fonts/Font193.ttf") format("truetype");
}

@font-face {
  font-family: "Font194";
  src: url("./assets/fonts/Font194.ttf") format("truetype");
}

@font-face {
  font-family: "Font195";
  src: url("./assets/fonts/Font195.ttf") format("truetype");
}

@font-face {
  font-family: "Font196";
  src: url("./assets/fonts/Font196.ttf") format("truetype");
}

@font-face {
  font-family: "Font197";
  src: url("./assets/fonts/Font197.ttf") format("truetype");
}

@font-face {
  font-family: "Font198";
  src: url("./assets/fonts/Font198.ttf") format("truetype");
}

@font-face {
  font-family: "Font199";
  src: url("./assets/fonts/Font199.ttf") format("truetype");
}

@font-face {
  font-family: "Font200";
  src: url("./assets/fonts/Font200.ttf") format("truetype");
}

@font-face {
  font-family: "Font201";
  src: url("./assets/fonts/Font201.ttf") format("truetype");
}

@font-face {
  font-family: "Font202";
  src: url("./assets/fonts/Font202.ttf") format("truetype");
}


@font-face {
  font-family: "Font203";
  src: url("./assets/fonts/Font203.ttf") format("truetype");
}

@font-face {
  font-family: "Font204";
  src: url("./assets/fonts/Font204.ttf") format("truetype");
}

@font-face {
  font-family: "Font205";
  src: url("./assets/fonts/Font205.ttf") format("truetype");
}

@font-face {
  font-family: "Font206";
  src: url("./assets/fonts/Font206.ttf") format("truetype");
}

@font-face {
  font-family: "Font207";
  src: url("./assets/fonts/Font207.ttf") format("truetype");
}

@font-face {
  font-family: "Font208";
  src: url("./assets/fonts/Font208.ttf") format("truetype");
}

@font-face {
  font-family: "Font209";
  src: url("./assets/fonts/Font209.ttf") format("truetype");
}

@font-face {
  font-family: "Font210";
  src: url("./assets/fonts/Font210.ttf") format("truetype");
}

@font-face {
  font-family: "Font211";
  src: url("./assets/fonts/Font211.ttf") format("truetype");
}

@font-face {
  font-family: "Font212";
  src: url("./assets/fonts/Font212.ttf") format("truetype");
}

@font-face {
  font-family: "Font213";
  src: url("./assets/fonts/Font213.ttf") format("truetype");
}

@font-face {
  font-family: "Font214";
  src: url("./assets/fonts/Font194.ttf") format("truetype");
}

@font-face {
  font-family: "Font215";
  src: url("./assets/fonts/Font195.ttf") format("truetype");
}

@font-face {
  font-family: "Font216";
  src: url("./assets/fonts/Font196.ttf") format("truetype");
}

@font-face {
  font-family: "Font217";
  src: url("./assets/fonts/Font197.ttf") format("truetype");
}

@font-face {
  font-family: "Font218";
  src: url("./assets/fonts/Font198.ttf") format("truetype");
}

@font-face {
  font-family: "Font219";
  src: url("./assets/fonts/Font199.ttf") format("truetype");
}

@font-face {
  font-family: "Font220";
  src: url("./assets/fonts/Font200.ttf") format("truetype");
}

@font-face {
  font-family: "Font221";
  src: url("./assets/fonts/Font201.ttf") format("truetype");
}

@font-face {
  font-family: "Font222";
  src: url("./assets/fonts/Font202.ttf") format("truetype");
}


@font-face {
  font-family: "Font223";
  src: url("./assets/fonts/Font203.ttf") format("truetype");
}

@font-face {
  font-family: "Font224";
  src: url("./assets/fonts/Font204.ttf") format("truetype");
}

@font-face {
  font-family: "Font225";
  src: url("./assets/fonts/Font205.ttf") format("truetype");
}

@font-face {
  font-family: "Font226";
  src: url("./assets/fonts/Font206.ttf") format("truetype");
}

@font-face {
  font-family: "Font227";
  src: url("./assets/fonts/Font207.ttf") format("truetype");
}

@font-face {
  font-family: "Font228";
  src: url("./assets/fonts/Font208.ttf") format("truetype");
}

@font-face {
  font-family: "Font229";
  src: url("./assets/fonts/Font209.ttf") format("truetype");
}

@font-face {
  font-family: "Font230";
  src: url("./assets/fonts/Font210.ttf") format("truetype");
}

@font-face {
  font-family: "Font231";
  src: url("./assets/fonts/Font211.ttf") format("truetype");
}

@font-face {
  font-family: "Font232";
  src: url("./assets/fonts/Font212.ttf") format("truetype");
}

@font-face {
  font-family: "Font233";
  src: url("./assets/fonts/Font213.ttf") format("truetype");
}


@font-face {
  font-family: "Font234";
  src: url("./assets/fonts/Font234.ttf") format("truetype");
}

@font-face {
  font-family: "Font235";
  src: url("./assets/fonts/Font235.ttf") format("truetype");
}

@font-face {
  font-family: "Font236";
  src: url("./assets/fonts/Font236.ttf") format("truetype");
}

@font-face {
  font-family: "Font237";
  src: url("./assets/fonts/Font237.ttf") format("truetype");
}

@font-face {
  font-family: "Font238";
  src: url("./assets/fonts/Font238.ttf") format("truetype");
}

@font-face {
  font-family: "Font239";
  src: url("./assets/fonts/Font239.ttf") format("truetype");
}

@font-face {
  font-family: "Font240";
  src: url("./assets/fonts/Font240.ttf") format("truetype");
}

@font-face {
  font-family: "Font241";
  src: url("./assets/fonts/Font241.ttf") format("truetype");
}

@font-face {
  font-family: "Font242";
  src: url("./assets/fonts/Font242.ttf") format("truetype");
}


@font-face {
  font-family: "Font243";
  src: url("./assets/fonts/Font243.ttf") format("truetype");
}

@font-face {
  font-family: "Font244";
  src: url("./assets/fonts/Font244.ttf") format("truetype");
}

@font-face {
  font-family: "Font245";
  src: url("./assets/fonts/Font245.ttf") format("truetype");
}

@font-face {
  font-family: "Font246";
  src: url("./assets/fonts/Font246.ttf") format("truetype");
}

@font-face {
  font-family: "Font247";
  src: url("./assets/fonts/Font247.ttf") format("truetype");
}

@font-face {
  font-family: "Font248";
  src: url("./assets/fonts/Font248.ttf") format("truetype");
}

@font-face {
  font-family: "Font249";
  src: url("./assets/fonts/Font249.ttf") format("truetype");
}

@font-face {
  font-family: "Font250";
  src: url("./assets/fonts/Font250.ttf") format("truetype");
}

@font-face {
  font-family: "Font251";
  src: url("./assets/fonts/Font251.ttf") format("truetype");
}

@font-face {
  font-family: "Font252";
  src: url("./assets/fonts/Font252.ttf") format("truetype");
}

@font-face {
  font-family: "Font253";
  src: url("./assets/fonts/Font253.ttf") format("truetype");
}

@font-face {
  font-family: "Font254";
  src: url("./assets/fonts/Font254.ttf") format("truetype");
}

@font-face {
  font-family: "Font255";
  src: url("./assets/fonts/Font255.ttf") format("truetype");
}

@font-face {
  font-family: "Font256";
  src: url("./assets/fonts/Font256.ttf") format("truetype");
}

@font-face {
  font-family: "Font257";
  src: url("./assets/fonts/Font257.ttf") format("truetype");
}

@font-face {
  font-family: "Font258";
  src: url("./assets/fonts/Font258.ttf") format("truetype");
}

@font-face {
  font-family: "Font259";
  src: url("./assets/fonts/Font259.ttf") format("truetype");
}

@font-face {
  font-family: "Font260";
  src: url("./assets/fonts/Font260.ttf") format("truetype");
}

@font-face {
  font-family: "Font261";
  src: url("./assets/fonts/Font261.ttf") format("truetype");
}

@font-face {
  font-family: "Font262";
  src: url("./assets/fonts/Font262.ttf") format("truetype");
}


@font-face {
  font-family: "Font263";
  src: url("./assets/fonts/Font263.ttf") format("truetype");
}

@font-face {
  font-family: "Font264";
  src: url("./assets/fonts/Font264.ttf") format("truetype");
}

@font-face {
  font-family: "Font265";
  src: url("./assets/fonts/Font265.ttf") format("truetype");
}

@font-face {
  font-family: "Font266";
  src: url("./assets/fonts/Font266.ttf") format("truetype");
}

@font-face {
  font-family: "Font267";
  src: url("./assets/fonts/Font267.ttf") format("truetype");
}

@font-face {
  font-family: "Font268";
  src: url("./assets/fonts/Font268.ttf") format("truetype");
}

@font-face {
  font-family: "Font269";
  src: url("./assets/fonts/Font269.ttf") format("truetype");
}

@font-face {
  font-family: "Font270";
  src: url("./assets/fonts/Font270.ttf") format("truetype");
}

@font-face {
  font-family: "Font271";
  src: url("./assets/fonts/Font271.ttf") format("truetype");
}

@font-face {
  font-family: "Font272";
  src: url("./assets/fonts/Font272.ttf") format("truetype");
}

@font-face {
  font-family: "Font273";
  src: url("./assets/fonts/Font273.ttf") format("truetype");
}


@font-face {
  font-family: "Font254";
  src: url("./assets/fonts/Font254.ttf") format("truetype");
}

@font-face {
  font-family: "Font255";
  src: url("./assets/fonts/Font255.ttf") format("truetype");
}

@font-face {
  font-family: "Font256";
  src: url("./assets/fonts/Font256.ttf") format("truetype");
}

@font-face {
  font-family: "Font257";
  src: url("./assets/fonts/Font257.ttf") format("truetype");
}

@font-face {
  font-family: "Font258";
  src: url("./assets/fonts/Font258.ttf") format("truetype");
}

@font-face {
  font-family: "Font259";
  src: url("./assets/fonts/Font259.ttf") format("truetype");
}

@font-face {
  font-family: "Font260";
  src: url("./assets/fonts/Font260.ttf") format("truetype");
}

@font-face {
  font-family: "Font261";
  src: url("./assets/fonts/Font261.ttf") format("truetype");
}

@font-face {
  font-family: "Font262";
  src: url("./assets/fonts/Font262.ttf") format("truetype");
}


@font-face {
  font-family: "Font263";
  src: url("./assets/fonts/Font263.ttf") format("truetype");
}

@font-face {
  font-family: "Font264";
  src: url("./assets/fonts/Font264.ttf") format("truetype");
}

@font-face {
  font-family: "Font265";
  src: url("./assets/fonts/Font265.ttf") format("truetype");
}

@font-face {
  font-family: "Font266";
  src: url("./assets/fonts/Font266.ttf") format("truetype");
}

@font-face {
  font-family: "Font267";
  src: url("./assets/fonts/Font267.ttf") format("truetype");
}

@font-face {
  font-family: "Font268";
  src: url("./assets/fonts/Font268.ttf") format("truetype");
}

@font-face {
  font-family: "Font269";
  src: url("./assets/fonts/Font269.ttf") format("truetype");
}

@font-face {
  font-family: "Font270";
  src: url("./assets/fonts/Font270.ttf") format("truetype");
}

@font-face {
  font-family: "Font271";
  src: url("./assets/fonts/Font271.ttf") format("truetype");
}

@font-face {
  font-family: "Font272";
  src: url("./assets/fonts/Font272.ttf") format("truetype");
}

@font-face {
  font-family: "Font273";
  src: url("./assets/fonts/Font273.ttf") format("truetype");
}

@font-face {
  font-family: "Font274";
  src: url("./assets/fonts/Font274.ttf") format("truetype");
}

@font-face {
  font-family: "Font275";
  src: url("./assets/fonts/Font275.ttf") format("truetype");
}

@font-face {
  font-family: "Font276";
  src: url("./assets/fonts/Font276.ttf") format("truetype");
}

@font-face {
  font-family: "Font277";
  src: url("./assets/fonts/Font277.ttf") format("truetype");
}

@font-face {
  font-family: "Font278";
  src: url("./assets/fonts/Font278.ttf") format("truetype");
}

@font-face {
  font-family: "Font279";
  src: url("./assets/fonts/Font279.ttf") format("truetype");
}

@font-face {
  font-family: "Font280";
  src: url("./assets/fonts/Font280.ttf") format("truetype");
}

@font-face {
  font-family: "Font281";
  src: url("./assets/fonts/Font281.ttf") format("truetype");
}

@font-face {
  font-family: "Font282";
  src: url("./assets/fonts/Font282.ttf") format("truetype");
}


@font-face {
  font-family: "Font283";
  src: url("./assets/fonts/Font283.ttf") format("truetype");
}

@font-face {
  font-family: "Font284";
  src: url("./assets/fonts/Font284.ttf") format("truetype");
}

@font-face {
  font-family: "Font285";
  src: url("./assets/fonts/Font285.ttf") format("truetype");
}

@font-face {
  font-family: "Font286";
  src: url("./assets/fonts/Font286.ttf") format("truetype");
}

@font-face {
  font-family: "Font287";
  src: url("./assets/fonts/Font287.ttf") format("truetype");
}

@font-face {
  font-family: "Font288";
  src: url("./assets/fonts/Font288.ttf") format("truetype");
}

@font-face {
  font-family: "Font289";
  src: url("./assets/fonts/Font289.ttf") format("truetype");
}

@font-face {
  font-family: "Font290";
  src: url("./assets/fonts/Font290.ttf") format("truetype");
}

@font-face {
  font-family: "Font291";
  src: url("./assets/fonts/Font291.ttf") format("truetype");
}

@font-face {
  font-family: "Font292";
  src: url("./assets/fonts/Font292.ttf") format("truetype");
}

@font-face {
  font-family: "Font293";
  src: url("./assets/fonts/Font293.ttf") format("truetype");
}

@font-face {
  font-family: "Font294";
  src: url("./assets/fonts/Font294.ttf") format("truetype");
}

@font-face {
  font-family: "Font295";
  src: url("./assets/fonts/Font295.ttf") format("truetype");
}

@font-face {
  font-family: "Font296";
  src: url("./assets/fonts/Font296.ttf") format("truetype");
}

@font-face {
  font-family: "Font297";
  src: url("./assets/fonts/Font297.ttf") format("truetype");
}

@font-face {
  font-family: "Font298";
  src: url("./assets/fonts/Font298.ttf") format("truetype");
}

@font-face {
  font-family: "Font299";
  src: url("./assets/fonts/Font299.ttf") format("truetype");
}

@font-face {
  font-family: "Font300";
  src: url("./assets/fonts/Font300.ttf") format("truetype");
}

@font-face {
  font-family: "Font301";
  src: url("./assets/fonts/Font301.ttf") format("truetype");
}

@font-face {
  font-family: "Font302";
  src: url("./assets/fonts/Font302.ttf") format("truetype");
}


@font-face {
  font-family: "Font303";
  src: url("./assets/fonts/Font303.ttf") format("truetype");
}

@font-face {
  font-family: "Font304";
  src: url("./assets/fonts/Font304.ttf") format("truetype");
}

@font-face {
  font-family: "Font305";
  src: url("./assets/fonts/Font305.ttf") format("truetype");
}

@font-face {
  font-family: "Font306";
  src: url("./assets/fonts/Font306.ttf") format("truetype");
}

@font-face {
  font-family: "Font307";
  src: url("./assets/fonts/Font307.ttf") format("truetype");
}

@font-face {
  font-family: "Font308";
  src: url("./assets/fonts/Font308.ttf") format("truetype");
}

@font-face {
  font-family: "Font309";
  src: url("./assets/fonts/Font309.ttf") format("truetype");
}

@font-face {
  font-family: "Font310";
  src: url("./assets/fonts/Font310.ttf") format("truetype");
}

@font-face {
  font-family: "Font311";
  src: url("./assets/fonts/Font311.ttf") format("truetype");
}

@font-face {
  font-family: "Font312";
  src: url("./assets/fonts/Font312.ttf") format("truetype");
}

@font-face {
  font-family: "Font313";
  src: url("./assets/fonts/Font313.ttf") format("truetype");
}

@font-face {
  font-family: "Font314";
  src: url("./assets/fonts/Font314.ttf") format("truetype");
}

@font-face {
  font-family: "Font315";
  src: url("./assets/fonts/Font315.ttf") format("truetype");
}

@font-face {
  font-family: "Font316";
  src: url("./assets/fonts/Font316.ttf") format("truetype");
}

@font-face {
  font-family: "Font317";
  src: url("./assets/fonts/Font317.ttf") format("truetype");
}

@font-face {
  font-family: "Font318";
  src: url("./assets/fonts/Font318.ttf") format("truetype");
}

@font-face {
  font-family: "Font319";
  src: url("./assets/fonts/Font319.ttf") format("truetype");
}

@font-face {
  font-family: "Font320";
  src: url("./assets/fonts/Font320.ttf") format("truetype");
}

@font-face {
  font-family: "Font321";
  src: url("./assets/fonts/Font321.ttf") format("truetype");
}

@font-face {
  font-family: "Font322";
  src: url("./assets/fonts/Font322.ttf") format("truetype");
}


@font-face {
  font-family: "Font323";
  src: url("./assets/fonts/Font323.ttf") format("truetype");
}

@font-face {
  font-family: "Font324";
  src: url("./assets/fonts/Font324.ttf") format("truetype");
}

@font-face {
  font-family: "Font325";
  src: url("./assets/fonts/Font325.ttf") format("truetype");
}

@font-face {
  font-family: "Font326";
  src: url("./assets/fonts/Font326.ttf") format("truetype");
}

@font-face {
  font-family: "Font327";
  src: url("./assets/fonts/Font327.ttf") format("truetype");
}

@font-face {
  font-family: "Font328";
  src: url("./assets/fonts/Font328.ttf") format("truetype");
}

@font-face {
  font-family: "Font329";
  src: url("./assets/fonts/Font329.ttf") format("truetype");
}

@font-face {
  font-family: "Font330";
  src: url("./assets/fonts/Font330.ttf") format("truetype");
}

@font-face {
  font-family: "Font331";
  src: url("./assets/fonts/Font331.ttf") format("truetype");
}

@font-face {
  font-family: "Font332";
  src: url("./assets/fonts/Font332.ttf") format("truetype");
}

@font-face {
  font-family: "Font333";
  src: url("./assets/fonts/Font333.ttf") format("truetype");
}

@font-face {
  font-family: "Font334";
  src: url("./assets/fonts/Font334.ttf") format("truetype");
}

@font-face {
  font-family: "Font335";
  src: url("./assets/fonts/Font335.ttf") format("truetype");
}

@font-face {
  font-family: "Font336";
  src: url("./assets/fonts/Font336.ttf") format("truetype");
}

@font-face {
  font-family: "Font337";
  src: url("./assets/fonts/Font337.ttf") format("truetype");
}

@font-face {
  font-family: "Font338";
  src: url("./assets/fonts/Font338.ttf") format("truetype");
}

@font-face {
  font-family: "Font339";
  src: url("./assets/fonts/Font339.ttf") format("truetype");
}

@font-face {
  font-family: "Font340";
  src: url("./assets/fonts/Font340.ttf") format("truetype");
}

@font-face {
  font-family: "Font341";
  src: url("./assets/fonts/Font341.ttf") format("truetype");
}

@font-face {
  font-family: "Font342";
  src: url("./assets/fonts/Font342.ttf") format("truetype");
}


@font-face {
  font-family: "Font343";
  src: url("./assets/fonts/Font343.ttf") format("truetype");
}

@font-face {
  font-family: "Font344";
  src: url("./assets/fonts/Font344.ttf") format("truetype");
}

@font-face {
  font-family: "Font345";
  src: url("./assets/fonts/Font345.ttf") format("truetype");
}

@font-face {
  font-family: "Font346";
  src: url("./assets/fonts/Font346.ttf") format("truetype");
}

@font-face {
  font-family: "Font347";
  src: url("./assets/fonts/Font347.ttf") format("truetype");
}

@font-face {
  font-family: "Font348";
  src: url("./assets/fonts/Font348.ttf") format("truetype");
}

@font-face {
  font-family: "Font349";
  src: url("./assets/fonts/Font349.ttf") format("truetype");
}

@font-face {
  font-family: "Font350";
  src: url("./assets/fonts/Font350.ttf") format("truetype");
}

@font-face {
  font-family: "Font351";
  src: url("./assets/fonts/Font351.ttf") format("truetype");
}

@font-face {
  font-family: "Font352";
  src: url("./assets/fonts/Font352.ttf") format("truetype");
}

@font-face {
  font-family: "Font353";
  src: url("./assets/fonts/Font353.ttf") format("truetype");
}















@font-face {
  font-family: "Font354";
  src: url("./assets/fonts/Font314.ttf") format("truetype");
}

@font-face {
  font-family: "Font355";
  src: url("./assets/fonts/Font315.ttf") format("truetype");
}

@font-face {
  font-family: "Font356";
  src: url("./assets/fonts/Font316.ttf") format("truetype");
}

@font-face {
  font-family: "Font357";
  src: url("./assets/fonts/Font317.ttf") format("truetype");
}

@font-face {
  font-family: "Font358";
  src: url("./assets/fonts/Font318.ttf") format("truetype");
}

@font-face {
  font-family: "Font359";
  src: url("./assets/fonts/Font319.ttf") format("truetype");
}

@font-face {
  font-family: "Font360";
  src: url("./assets/fonts/Font320.ttf") format("truetype");
}

@font-face {
  font-family: "Font361";
  src: url("./assets/fonts/Font321.ttf") format("truetype");
}

@font-face {
  font-family: "Font362";
  src: url("./assets/fonts/Font322.ttf") format("truetype");
}


@font-face {
  font-family: "Font363";
  src: url("./assets/fonts/Font323.ttf") format("truetype");
}

@font-face {
  font-family: "Font364";
  src: url("./assets/fonts/Font324.ttf") format("truetype");
}

@font-face {
  font-family: "Font365";
  src: url("./assets/fonts/Font325.ttf") format("truetype");
}

@font-face {
  font-family: "Font366";
  src: url("./assets/fonts/Font326.ttf") format("truetype");
}

@font-face {
  font-family: "Font367";
  src: url("./assets/fonts/Font327.ttf") format("truetype");
}

@font-face {
  font-family: "Font368";
  src: url("./assets/fonts/Font328.ttf") format("truetype");
}

@font-face {
  font-family: "Font369";
  src: url("./assets/fonts/Font329.ttf") format("truetype");
}

@font-face {
  font-family: "Font370";
  src: url("./assets/fonts/Font330.ttf") format("truetype");
}

@font-face {
  font-family: "Font371";
  src: url("./assets/fonts/Font331.ttf") format("truetype");
}

@font-face {
  font-family: "Font372";
  src: url("./assets/fonts/Font332.ttf") format("truetype");
}

@font-face {
  font-family: "Font373";
  src: url("./assets/fonts/Font333.ttf") format("truetype");
}

@font-face {
  font-family: "Font374";
  src: url("./assets/fonts/Font334.ttf") format("truetype");
}

@font-face {
  font-family: "Font375";
  src: url("./assets/fonts/Font335.ttf") format("truetype");
}

@font-face {
  font-family: "Font376";
  src: url("./assets/fonts/Font336.ttf") format("truetype");
}

@font-face {
  font-family: "Font377";
  src: url("./assets/fonts/Font337.ttf") format("truetype");
}

@font-face {
  font-family: "Font378";
  src: url("./assets/fonts/Font338.ttf") format("truetype");
}

@font-face {
  font-family: "Font379";
  src: url("./assets/fonts/Font339.ttf") format("truetype");
}

@font-face {
  font-family: "Font380";
  src: url("./assets/fonts/Font340.ttf") format("truetype");
}

@font-face {
  font-family: "Font381";
  src: url("./assets/fonts/Font341.ttf") format("truetype");
}

@font-face {
  font-family: "Font382";
  src: url("./assets/fonts/Font342.ttf") format("truetype");
}


@font-face {
  font-family: "Font383";
  src: url("./assets/fonts/Font343.ttf") format("truetype");
}

@font-face {
  font-family: "Font384";
  src: url("./assets/fonts/Font344.ttf") format("truetype");
}

@font-face {
  font-family: "Font385";
  src: url("./assets/fonts/Font345.ttf") format("truetype");
}

@font-face {
  font-family: "Font386";
  src: url("./assets/fonts/Font346.ttf") format("truetype");
}

@font-face {
  font-family: "Font387";
  src: url("./assets/fonts/Font347.ttf") format("truetype");
}

@font-face {
  font-family: "Font388";
  src: url("./assets/fonts/Font388.ttf") format("truetype");
}

@font-face {
  font-family: "Font389";
  src: url("./assets/fonts/Font389.ttf") format("truetype");
}

@font-face {
  font-family: "Font390";
  src: url("./assets/fonts/Font390.ttf") format("truetype");
}

@font-face {
  font-family: "Font391";
  src: url("./assets/fonts/Font391.ttf") format("truetype");
}

@font-face {
  font-family: "Font392";
  src: url("./assets/fonts/Font392.ttf") format("truetype");
}

@font-face {
  font-family: "Font393";
  src: url("./assets/fonts/Font393.ttf") format("truetype");
}

@font-face {
  font-family: "Font394";
  src: url("./assets/fonts/Font394.ttf") format("truetype");
}

@font-face {
  font-family: "Font395";
  src: url("./assets/fonts/Font395.ttf") format("truetype");
}

@font-face {
  font-family: "Font396";
  src: url("./assets/fonts/Font396.ttf") format("truetype");
}

@font-face {
  font-family: "Font397";
  src: url("./assets/fonts/Font397.ttf") format("truetype");
}

@font-face {
  font-family: "Font398";
  src: url("./assets/fonts/Font398.ttf") format("truetype");
}

@font-face {
  font-family: "Font399";
  src: url("./assets/fonts/Font399.ttf") format("truetype");
}

@font-face {
  font-family: "Font400";
  src: url("./assets/fonts/Font400.ttf") format("truetype");
}

@font-face {
  font-family: "Font401";
  src: url("./assets/fonts/Font401.ttf") format("truetype");
}

@font-face {
  font-family: "Font402";
  src: url("./assets/fonts/Font402.ttf") format("truetype");
}


@font-face {
  font-family: "Font403";
  src: url("./assets/fonts/Font403.ttf") format("truetype");
}

@font-face {
  font-family: "Font404";
  src: url("./assets/fonts/Font404.ttf") format("truetype");
}

@font-face {
  font-family: "Font405";
  src: url("./assets/fonts/Font405.ttf") format("truetype");
}

@font-face {
  font-family: "Font406";
  src: url("./assets/fonts/Font406.ttf") format("truetype");
}

@font-face {
  font-family: "Font407";
  src: url("./assets/fonts/Font407.ttf") format("truetype");
}

@font-face {
  font-family: "Font408";
  src: url("./assets/fonts/Font408.ttf") format("truetype");
}

@font-face {
  font-family: "Font409";
  src: url("./assets/fonts/Font409.ttf") format("truetype");
}

@font-face {
  font-family: "Font410";
  src: url("./assets/fonts/Font410.ttf") format("truetype");
}

@font-face {
  font-family: "Font411";
  src: url("./assets/fonts/Font411.ttf") format("truetype");
}

@font-face {
  font-family: "Font412";
  src: url("./assets/fonts/Font412.ttf") format("truetype");
}

@font-face {
  font-family: "Font413";
  src: url("./assets/fonts/Font413.ttf") format("truetype");
}

@font-face {
  font-family: "Font414";
  src: url("./assets/fonts/Font414.ttf") format("truetype");
}

@font-face {
  font-family: "Font415";
  src: url("./assets/fonts/Font415.ttf") format("truetype");
}

@font-face {
  font-family: "Font416";
  src: url("./assets/fonts/Font416.ttf") format("truetype");
}

@font-face {
  font-family: "Font417";
  src: url("./assets/fonts/Font417.ttf") format("truetype");
}

@font-face {
  font-family: "Font418";
  src: url("./assets/fonts/Font418.ttf") format("truetype");
}

@font-face {
  font-family: "Font419";
  src: url("./assets/fonts/Font419.ttf") format("truetype");
}

@font-face {
  font-family: "Font420";
  src: url("./assets/fonts/Font420.ttf") format("truetype");
}

@font-face {
  font-family: "Font421";
  src: url("./assets/fonts/Font421.ttf") format("truetype");
}

@font-face {
  font-family: "Font422";
  src: url("./assets/fonts/Font422.ttf") format("truetype");
}


@font-face {
  font-family: "Font423";
  src: url("./assets/fonts/Font423.ttf") format("truetype");
}

@font-face {
  font-family: "Font424";
  src: url("./assets/fonts/Font424.ttf") format("truetype");
}

@font-face {
  font-family: "Font425";
  src: url("./assets/fonts/Font425.ttf") format("truetype");
}

@font-face {
  font-family: "Font426";
  src: url("./assets/fonts/Font426.ttf") format("truetype");
}

@font-face {
  font-family: "Font427";
  src: url("./assets/fonts/Font427.ttf") format("truetype");
}

@font-face {
  font-family: "Font428";
  src: url("./assets/fonts/Font428.ttf") format("truetype");
}

@font-face {
  font-family: "Font429";
  src: url("./assets/fonts/Font429.ttf") format("truetype");
}

@font-face {
  font-family: "Font430";
  src: url("./assets/fonts/Font430.ttf") format("truetype");
}

@font-face {
  font-family: "Font431";
  src: url("./assets/fonts/Font431.ttf") format("truetype");
}

@font-face {
  font-family: "Font432";
  src: url("./assets/fonts/Font432.ttf") format("truetype");
}

@font-face {
  font-family: "Font433";
  src: url("./assets/fonts/Font433.ttf") format("truetype");
}

@font-face {
  font-family: "Font434";
  src: url("./assets/fonts/Font434.ttf") format("truetype");
}

@font-face {
  font-family: "Font435";
  src: url("./assets/fonts/Font435.ttf") format("truetype");
}

@font-face {
  font-family: "Font436";
  src: url("./assets/fonts/Font436.ttf") format("truetype");
}

@font-face {
  font-family: "Font437";
  src: url("./assets/fonts/Font437.ttf") format("truetype");
}

@font-face {
  font-family: "Font438";
  src: url("./assets/fonts/Font438.ttf") format("truetype");
}

@font-face {
  font-family: "Font439";
  src: url("./assets/fonts/Font439.ttf") format("truetype");
}

@font-face {
  font-family: "Font440";
  src: url("./assets/fonts/Font440.ttf") format("truetype");
}

@font-face {
  font-family: "Font441";
  src: url("./assets/fonts/Font441.ttf") format("truetype");
}

@font-face {
  font-family: "Font442";
  src: url("./assets/fonts/Font442.ttf") format("truetype");
}


@font-face {
  font-family: "Font443";
  src: url("./assets/fonts/Font443.ttf") format("truetype");
}

@font-face {
  font-family: "Font444";
  src: url("./assets/fonts/Font444.ttf") format("truetype");
}

@font-face {
  font-family: "Font445";
  src: url("./assets/fonts/Font445.ttf") format("truetype");
}

@font-face {
  font-family: "Font446";
  src: url("./assets/fonts/Font446.ttf") format("truetype");
}

@font-face {
  font-family: "Font447";
  src: url("./assets/fonts/Font447.ttf") format("truetype");
}

@font-face {
  font-family: "Font448";
  src: url("./assets/fonts/Font448.ttf") format("truetype");
}

@font-face {
  font-family: "Font449";
  src: url("./assets/fonts/Font449.ttf") format("truetype");
}

@font-face {
  font-family: "Font450";
  src: url("./assets/fonts/Font450.ttf") format("truetype");
}

@font-face {
  font-family: "Font451";
  src: url("./assets/fonts/Font451.ttf") format("truetype");
}

@font-face {
  font-family: "Font452";
  src: url("./assets/fonts/Font452.ttf") format("truetype");
}

@font-face {
  font-family: "Font453";
  src: url("./assets/fonts/Font453.ttf") format("truetype");
}

@font-face {
  font-family: "Font454";
  src: url("./assets/fonts/Font454.ttf") format("truetype");
}

@font-face {
  font-family: "Font455";
  src: url("./assets/fonts/Font455.ttf") format("truetype");
}

@font-face {
  font-family: "Font456";
  src: url("./assets/fonts/Font456.ttf") format("truetype");
}

@font-face {
  font-family: "Font457";
  src: url("./assets/fonts/Font457.ttf") format("truetype");
}

@font-face {
  font-family: "Font458";
  src: url("./assets/fonts/Font458.ttf") format("truetype");
}

@font-face {
  font-family: "Font459";
  src: url("./assets/fonts/Font459.ttf") format("truetype");
}

@font-face {
  font-family: "Font460";
  src: url("./assets/fonts/Font460.ttf") format("truetype");
}

@font-face {
  font-family: "Font461";
  src: url("./assets/fonts/Font461.ttf") format("truetype");
}

@font-face {
  font-family: "Font462";
  src: url("./assets/fonts/Font462.ttf") format("truetype");
}

@font-face {
  font-family: "Font463";
  src: url("./assets/fonts/Font463.ttf") format("truetype");
}

@font-face {
  font-family: "Font464";
  src: url("./assets/fonts/Font464.ttf") format("truetype");
}

@font-face {
  font-family: "Font465";
  src: url("./assets/fonts/Font465.ttf") format("truetype");
}

@font-face {
  font-family: "Font466";
  src: url("./assets/fonts/Font466.ttf") format("truetype");
}

@font-face {
  font-family: "Font467";
  src: url("./assets/fonts/Font467.ttf") format("truetype");
}

@font-face {
  font-family: "Font468";
  src: url("./assets/fonts/Font468.ttf") format("truetype");
}

@font-face {
  font-family: "Font469";
  src: url("./assets/fonts/Font469.ttf") format("truetype");
}

@font-face {
  font-family: "Font470";
  src: url("./assets/fonts/Font470.ttf") format("truetype");
}

@font-face {
  font-family: "Font471";
  src: url("./assets/fonts/Font471.ttf") format("truetype");
}

@font-face {
  font-family: "Font472";
  src: url("./assets/fonts/Font472.ttf") format("truetype");
}

@font-face {
  font-family: "Font473";
  src: url("./assets/fonts/Font473.ttf") format("truetype");
}

@font-face {
  font-family: "Font474";
  src: url("./assets/fonts/Font474.ttf") format("truetype");
}

@font-face {
  font-family: "Font475";
  src: url("./assets/fonts/Font475.ttf") format("truetype");
}

@font-face {
  font-family: "Font476";
  src: url("./assets/fonts/Font476.ttf") format("truetype");
}

@font-face {
  font-family: "Font477";
  src: url("./assets/fonts/Font477.ttf") format("truetype");
}

@font-face {
  font-family: "Font478";
  src: url("./assets/fonts/Font478.ttf") format("truetype");
}

@font-face {
  font-family: "Font479";
  src: url("./assets/fonts/Font479.ttf") format("truetype");
}

@font-face {
  font-family: "Font480";
  src: url("./assets/fonts/Font480.ttf") format("truetype");
}

@font-face {
  font-family: "Font481";
  src: url("./assets/fonts/Font481.ttf") format("truetype");
}

@font-face {
  font-family: "Font482";
  src: url("./assets/fonts/Font482.ttf") format("truetype");
}

@font-face {
  font-family: "Font483";
  src: url("./assets/fonts/Font483.ttf") format("truetype");
}